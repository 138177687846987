/*
 *           ==== IMPORTANT NOTE ====
 *
 *             Keep it in sync with
 *    app/gleam-shared/embeds/GalleryPreview.js
 *  app/gallery-v2/src/utils/shared-constants.less
 *
 *             ==== THANK YOU! ====
 */

// styles
export const LOW_OPACITY = 0.4
export const MOBILE_THRESHOLD = 550
export const GALLERY_MIN_WIDTH = 250
export const GALLERY_MIN_HEIGHT = 120

//stats
export const READY_ATTR = 'data-ready'
export const LOADED_ATTR = 'data-loaded'
export const OPEN_POPUPS_ATTR = 'data-open-popups'

// initialization
export const OVERLAY_CLASS = 'ggs-bootstrap-overlay'
export const BOOTSTRAPPED_ATTR = 'data-bootstrapped'
export const GALLERY_EMBED_ATTR = 'data-gallery-embed'
export const GALLERY_INCLUDE_ATTR = 'data-gallery-include'

// mode
export const MODE_ATTR = 'data-mode'
export const HOSTED_MODE = 'hosted-mode'
export const IFRAME_MODE = 'iframe-mode'
export const IFRAMELESS_MODE = 'iframeless-mode'

// order
export const RANDOM_ORDER = 'random'
export const RECENT_ORDER = 'recent'
export const POPULAR_ORDER = 'popular'
export const RECENT_FIELD = 'external_created_at'
export const POPULAR_FIELD = 'votes_count'

// limits
export const TOP_POSTS_LIMIT_LE = 12
export const TOP_POSTS_LIMIT_DEFAULT = 64
export const BELOW_POSTS_LIMIT_DEFAULT = 32
export const ABOVE_POSTS_LIMIT_DEFAULT = 12

// embed-modal
export const embedModal = {
  HOR_ALIGN_CONFIG_DEFAULT: 'center',
  VER_ALIGN_CONFIG_DEFAULT: 'top',
  MIN_WIDTH_CONFIG_DEFAULT: `${GALLERY_MIN_WIDTH}px`,
  MIN_HEIGHT_CONFIG_DEFAULT: `${GALLERY_MIN_HEIGHT}px`,
  MAX_WIDTH_CONFIG_DEFAULT: '100%',
  MAX_HEIGHT_CONFIG_DEFAULT: '100vh'
}
